export const initFaqDropdowns = () => {
	document.querySelector('#faqs .internal_content').addEventListener('click', e => {
		const clickedHeading = e.target.closest('h4');

		if(clickedHeading) {
			const theItem = clickedHeading.closest('li');

			theItem.switchAttribute('state', 'open', 'closed');
		}
	});

	setTimeout(() => {
		document.querySelector('#faqs ul li').setAttribute('state', 'open');
	}, 750);
};