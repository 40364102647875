let submitInProgress = false;

const updateResponseMessage = (errors) => {
	var response = '';
	console.log(errors);
	if(errors.length === 1){
		response = "The "+errors[0]+" field is required.";
	}else if(errors.length === 2){
		response = "The "+errors[0]+" and "+errors[1]+" fields are required.";
	}else{
		let fields = "";
		for(let x = 0; x < errors.length; x++){
			fields += errors[x];
			if(x < errors.length - 1){
				fields += ', ';
			}
			if(x === errors.length - 2){
				fields += 'and ';
			}
		}
		response = "The "+fields+" fields are required.";
	}
	document.querySelector('.response_message').innerHTML = "<span class='error'>"+response+"</span>";
};

/**
 * 
 * @param {Object} param0 Object of named parameters
 * 
 * elementSelector : the css selector for the form element
 * required : (String[]) 
 */
const _listenToForm = ({
	elementSelector = '#contact_form',
	required = ['name', 'email', 'phone']
} = {}) => {
    
	document.querySelector(elementSelector).addEventListener('submit', function(e) {
		if(document.getElementById('hp').value !== '')return;
		const formEl = document.querySelector(elementSelector);
		Array.prototype.forEach.call(formEl.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		formEl.querySelector('.response_message').innerHTML = '';
	
		if(submitInProgress) {
			return;
		}
	
		// var checkFields = new Array('name', 'email', 'message');
		let errorsFound = new Array();
		required.forEach(function(item){
			if(document.getElementById(item+'_input').value === ''){
				document.getElementById(item+'_input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if(!submitInProgress && errorsFound.length === 0){
			submitInProgress = true;
            
			let request = new XMLHttpRequest(),
				method = 'POST',
				url = formEl.getAttribute('action'),
				data = new FormData(formEl);
	
			request.onreadystatechange = function() {
				if(request.readyState === 4 && request.status === 200) {
					let response = JSON.parse(request.responseText);
					if(response.ret_det.success){
						let input = document.getElementById('contact_form_submit');
						input.parentElement.removeChild(input);
						formEl.querySelector('.response_message').innerHTML = "<span class='success'>"+response.ret_det.message+"</span>";
					}else{
						if(typeof response.data.error_fields !== 'undefined'){
							let errors = [];
							for(let x = 0; x < response.data.error_fields.length; x++){
								errors.push(response.data.error_fields[x].field_name);
							}
							updateResponseMessage(errors);
						}else{
							formEl.querySelector('.response_message').innerHTML = "<span class='error'>"+response.ret_det.message+"</span>";
						}
					}
					submitInProgress = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if(errorsFound.length > 0){
			updateResponseMessage(errorsFound);
		}
		else{
			formEl.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
		e.stopImmediatePropagation();
		e.preventDefault();
	});


};

export const contactSubmit = _listenToForm;
