export const initMobileNav = () => {	
	document.querySelector('#toggle_mobile_nav').addEventListener('click', () => {
		document.querySelector('#toggle_mobile_nav').switchAttribute('state', 'open', 'closed');
		document.querySelector('#nav').switchAttribute('state', 'open', 'closed');
	});
	
	document.querySelector('#nav').addEventListener('click', e => {
		const clickedItem = e.target.closest('#nav>li');
		const subMenu = clickedItem.querySelector('ul');
		if(subMenu) {
			subMenu.switchAttribute('state', 'open', 'closed');
		}
	});
};

export const initDoubleClick = () => {
	document.querySelectorAll('nav a[d_click]').forEach(i => {
		i.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if(!i.hasAttribute('clicked') && (window.innerWidth > 750 || i.hasAttribute('mobile_d_click'))) {
					e.preventDefault();
					i.setAttribute('clicked', true);
				}
			}
		});
	});
};