import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

export const initFacilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		imagesAsBackgrounds: true,
		images: window.facCaroImgs,
		showDots: true,
		useChevrons: false
	});
};

export const initFacilityMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/ic_hardys_h.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});

	document.querySelector('#toggle_fac_map').addEventListener('click', () => {
		document.querySelector('#facility_map').switchAttribute('state', 'hide', 'show');
	});
};

export const initRateFilters = () => {
	const filters = document.querySelectorAll('#rates_filters .filter');
	const rows = document.querySelectorAll('.rates_row');

	document.querySelector('#rates_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			filters.forEach(i => {
				i.removeAttribute('active');
			});
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.setAttribute('active', true);
			}

			rows.forEach(i => {
				i.setAttribute('hide', true);
				if((i.getAttribute('size') === clickedFilter.getAttribute('size')) || clickedFilter.getAttribute('size') === 'all') {
					i.removeAttribute('hide');
				}
			});
		}
	});
};

export const initFacilityListingMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			mapHelper.createMap({
				element: document.querySelector('#facility_listing_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/ic_hardys_h.svg" alt="Visit Facility" /></a>`
					};
				},
			});
		});
};

export const initFilterParams = () => {	
	if(document.getElementById('rates_filters') && window.location.search.includes('?filter=')) {
		const filters = document.querySelectorAll('#rates_filters .filter');
		let filterParamater = window.location.search;
		let filterString = filterParamater.split('?filter=');
		let filterType = filterString[1];
		let filterClick = '';
		
		switch(filterType) {
			case 'small':
				filterClick = 'sm';
				break;
			case 'medium':
				filterClick = 'md';
				break;
			case 'large':
				filterClick = 'lg';
				break;
			case 'parking':
				filterClick = 'prk';
				break;
			default:
				filters.forEach(filter => {
					filter.removeAttribute('active');
				});
		}
		filters.forEach(filter => {
			if(filter.getAttribute('size') === filterClick || filter.getAttribute('filter') === filterClick){
				filter.click();
			}
		});
	}
};