import { AutomatitCarousel } from './automatit_carousel.m';

export const initHomeCaro = () => {
	const homeCaroImgs = [
		{src: "/static/images/content/slider_1.jpg", alt: ""},
		{src: "/static/images/content/slider_2.jpg", alt: ""},
		{src: "/static/images/content/slider_3.jpg", alt: ""},
		{src: "/static/images/content/slider_4.jpg", alt: ""},
		{src: "/static/images/content/slider_5.jpg", alt: ""},
	];

	AutomatitCarousel({
		element: document.querySelector('#home_caro'),
		images: homeCaroImgs,
		imagesAsBackgrounds: true,
		showDots: true
	});
};

export const initReviewsCaro = () => {
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#reviews_caro'),
		intervalTiming: false,
	});
	window.mobileReviewsCaro = AutomatitCarousel({
		element: document.querySelector('#reviews_caro_mobile'),
		intervalTiming: false,
	});
};