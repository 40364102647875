import { initMobileNav, initDoubleClick } from './header';
import { initFacilityCaro, initFacilityMap, initRateFilters, initFacilityListingMap, initFilterParams } from './facility';
import { initFaqDropdowns } from './storageHelp';
import { contactSubmit } from './contact.js';
import { initMultiMap } from './multiMap';
import { initHomeCaro, initReviewsCaro } from './home';

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}



initMobileNav();
initDoubleClick();

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if (document.querySelector('#supply_form')) {
	contactSubmit({
		elementSelector: '#supply_form',
		required: ['name', 'address', 'city', 'state', 'zip', 'phone', 'email', 'facility']
	});

	const items = document.querySelectorAll('#supply_form .item');
	
	document.querySelector('#supply_form').addEventListener('change', () => {
		let totalCost = 0.0;
		items.forEach(i => {
			const costCalculated = parseFloat(i.querySelector('input').value*i.dataset.cost).toFixed(2);
			i.querySelector('.item_total').innerHTML = `$${costCalculated}`;
			totalCost = parseFloat(totalCost) + parseFloat(costCalculated);
		});
		document.querySelector('.total_price span').innerHTML = `$${totalCost.toFixed(2)}`;
	});
}

// Home Map
if(document.querySelector('#multi_map')) {
	initMultiMap();
}

// Home carousel
if(document.querySelector('#home_caro')) {
	initHomeCaro();
}

// Reviews Carousel
if(document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}
if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_map')) {
	initFacilityMap();
}

if(document.querySelector('#rates_filters')) {
	initRateFilters();
}

if(document.querySelector('#facility_listing_map')) {
	initFacilityListingMap();
}

if(document.querySelector('#rates_filters')) {
	initFilterParams();
}

if(document.querySelector('#faqs')) {
	initFaqDropdowns();
}